import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

interface INPUT_TEXT {
  type?: 'text' | 'email';
  label: string;
  name: string;
  error?: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
}

export default function InputText(props: INPUT_TEXT) {
  return (
    <div>
      <label
        htmlFor={props.name}
        className='block text-sm font-medium text-gray-700'
      >
        {props.label}
      </label>
      <div className='mt-1 relative rounded-md shadow-sm'>
        <input
          readOnly={props.readOnly}
          disabled={props.readOnly}
          type={props.type ? props.type : 'text'}
          name={props.name}
          id={props.name}
          className={
            props.error
              ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
              : 'disabled:bg-gray-100 block w-full pr-10 focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md'
          }
          placeholder={props.placeholder}
          aria-invalid='true'
          aria-describedby={`${props.name}-error`}
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
        />
        {props.error && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
      {/*{props.error && (*/}
      {/*  <p className='mt-2 text-sm text-red-600' id={`${props.name}-error`}>*/}
      {/*    {props.error}*/}
      {/*  </p>*/}
      {/*)}*/}
    </div>
  );
}
