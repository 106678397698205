import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ORDER_DATA_ITEM } from '../constants/model';
import { reverseStatusMap } from '../constants/constants';
import { BASE_URL } from '../constants/constants';

import {
  SET_ORDER_PAYLOAD,
  SET_TOTAL_PAYLOAD,
  PAGE_SIZE_PAYLOAD,
  PAGE_NUMBER_PAYLOAD,
} from './common';

type IS_ORDER_DATA_LOADING_PAYLOAD = {
  isOrderDataLoading: boolean;
};

interface INITIAL_STATE {
  orders: ORDER_DATA_ITEM[];
  total: number;
  pageSize: string;
  pageSizeList: string[];
  pageNumber: number;
  isOrderDataLoading: boolean;
}

const initialState: INITIAL_STATE = {
  orders: [],
  total: 0,
  pageNumber: 1,
  pageSizeList: ['10', '20', '50', '100'],
  pageSize: '20',
  isOrderDataLoading: false,
};

const orderDataSlice = createSlice({
  name: 'orderDataSlice',
  initialState,
  reducers: {
    setOrders: (state, { payload }: PayloadAction<SET_ORDER_PAYLOAD>) => {
      state.orders = payload.orders;
    },
    setTotal: (state, { payload }: PayloadAction<SET_TOTAL_PAYLOAD>) => {
      state.total = payload.totalOrders;
    },
    setPageSize: (state, { payload }: PayloadAction<PAGE_SIZE_PAYLOAD>) => {
      state.pageSize = payload.pageSize;
    },
    setPageNumber: (state, { payload }: PayloadAction<PAGE_NUMBER_PAYLOAD>) => {
      state.pageNumber = payload.pageNumber;
    },
    setIsOrderDataLoading: (
      state,
      { payload }: PayloadAction<IS_ORDER_DATA_LOADING_PAYLOAD>,
    ) => {
      state.isOrderDataLoading = payload.isOrderDataLoading;
    },
  },
});

export const orderDataReducer = orderDataSlice.reducer;
export const {
  setOrders,
  setTotal,
  setPageSize,
  setPageNumber,
  setIsOrderDataLoading,
} = orderDataSlice.actions;

export function fetchOrders(
  company: string,
  bu: string,
  product: string,
  specType: string,
  status: string,
  pageSize: string,
  pageNumber: number,
) {
  return async (dispatch: any) => {
    dispatch(setIsOrderDataLoading({ isOrderDataLoading: true }));
    let url = `${BASE_URL}/order?pageSize=${pageSize}&page=${pageNumber}`;
    if (company !== 'Any') url += `&company=${encodeURIComponent(company)}`;
    if (bu !== 'Any') url += `&bu=${encodeURIComponent(bu)}`;
    if (product !== 'Any') url += `&product=${encodeURIComponent(product)}`;
    if (status !== 'Any') {
      const statusList = reverseStatusMap.get(status);
      url += `&status=${encodeURIComponent(statusList)}`;
    }
    if (specType !== 'Any') url += `&specType=${encodeURIComponent(specType)}`;
    const res = await fetch(url);
    const data = await res.json();
    dispatch(setOrders({ orders: data.items }));
    dispatch(setTotal({ totalOrders: data.total }));
    dispatch(setIsOrderDataLoading({ isOrderDataLoading: false }));
  };
}
