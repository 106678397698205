import { authReducer } from './auth';
import { filterReducer } from './filterSlice';
import { appLoadReducer } from './appLoadSlice';
import { orderDataReducer } from './orderDataSlice';
import { createOrderReducer } from './createOrderSlice';
import { alertReducer } from './AlertSlice';
import { myOrderReducer } from './MyOrdersSlice';

const reducer = {
  authReducer,
  filterReducer,
  appLoadReducer,
  orderDataReducer,
  createOrderReducer,
  alertReducer,
  myOrderReducer,
};

export default reducer;
