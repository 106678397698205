import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IS_LOADING_PAYLOAD = {
  isLoading: boolean;
};

type LOADING_MESSAGE_PAYLOAD = {
  loadingMessage: string;
};

const initialState = {
  isLoading: true,
  loadingMessage: '',
};

const appLoadSlice = createSlice({
  name: 'appLoadSlice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<IS_LOADING_PAYLOAD>) => {
      state.isLoading = payload.isLoading;
    },
    setLoadingMessage: (
      state,
      { payload }: PayloadAction<LOADING_MESSAGE_PAYLOAD>,
    ) => {
      state.loadingMessage = payload.loadingMessage;
    },
  },
});

export const appLoadReducer = appLoadSlice.reducer;
export const { setIsLoading, setLoadingMessage } = appLoadSlice.actions;
