import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import {
  setESWData,
  setCompany,
  setBu,
  setBuList,
  setProduct,
  setSpecTypeList,
  setStatusList,
  setSpecType,
  setStatus,
  setProductList,
} from './store/filterSlice';
import { getBUList } from './store/common';
import { createOrderActions } from './store/createOrderSlice';
import { setIsAdmin, setIsLoggedIn, setUserEmail } from './store/auth';
import { setIsLoading, setLoadingMessage } from './store/appLoadSlice';
import { TStore } from './store/store';
import AllOrders from './pages/all-orders';
import MyOrders from './pages/my-orders';
import { myOrderAction } from './store/MyOrdersSlice';
import { BASE_URL } from './constants/constants';
import './App.css';
import { useQuery } from '@apollo/client';
import { READ_PRODUCTS_AND_BU, READ_SPEC_TYPES } from './gql/Query';

function App() {
  const dispatch = useDispatch();

  const { isLoggedIn, isAdmin } = useSelector(
    (state: TStore) => state.authReducer,
  );

  async function isUserLoggedIn() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const userEmail = user.signInUserSession.idToken.payload.email;
        dispatch(createOrderActions.setYourEmail({ value: userEmail }));
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];

        dispatch(setIsLoggedIn({ isLoggedIn: true }));
        dispatch(
          myOrderAction.setRequesterEmail({
            requesterEmail: userEmail,
          }),
        );
        dispatch(setUserEmail({ userEmail }));
        if (groups.includes('Admin')) dispatch(setIsAdmin({ isAdmin: true }));
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function getFilterData() {
    try {
      dispatch(setIsLoading({ isLoading: true }));
      dispatch(setLoadingMessage({ loadingMessage: 'Loading filters...' }));
      let res = await fetch(`${BASE_URL}/esw-portfolio`);
      let data = await res.json();

      dispatch(setESWData({ eswDataList: data.items }));
      const buList = getBUList(data.items);
      dispatch(createOrderActions.setBillToBuList({ billToBuList: buList }));

      const company = localStorage.getItem('COMPANY');
      const bu = localStorage.getItem('BU');
      const product = localStorage.getItem('PRODUCT');

      if (company) dispatch(setCompany({ company: company }));
      if (bu) dispatch(setBu({ bu: bu }));
      if (product) dispatch(setProduct({ product: product }));

      res = await fetch(`${BASE_URL}/p2-constant-set`);
      data = await res.json();
      const specTypes: {
        type: string;
        readOnly: boolean;
        internalOnly: boolean;
      }[] = data.specTypes;

      const specTypeList: string[] = [];

      specTypes.forEach((specType) => {
        specTypeList.push(specType.type);
      });

      dispatch(setSpecTypeList({ specTypeList: specTypeList }));

      const statusList = ['Any', 'Cancelled', 'Done', 'In Progress', 'Paused'];
      dispatch(setStatusList({ statusList: statusList }));
      const specType = localStorage.getItem('SPEC_TYPE');
      const status = localStorage.getItem('STATUS');
      if (specType) dispatch(setSpecType({ specType: specType }));
      if (status) dispatch(setStatus({ status: status }));
      dispatch(setIsLoading({ isLoading: false }));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    isUserLoggedIn();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getFilterData().then(() => null);
    }
  }, [isLoggedIn, isAdmin]);

  let tempoProducts: string[] = [];
  let tempoBUs: string[] = [];
  const specTypes: string[] = [];
  let functionalSpecType: string[] = [];
  let coSpecType: string[] = [];

  // getting tempo product and BU list
  const {
    loading: loadingProducts,
    error: errorProducts,
    data: tempoProductList,
  } = useQuery(READ_PRODUCTS_AND_BU);
  if (!loadingProducts && !errorProducts && tempoProductList) {
    const sheetDataArray = tempoProductList.ReadDataInSheet.sheetData;
    tempoProducts = sheetDataArray.map((row: string[]) => {
      if (row[4] !== 'Central Product') return row[0];
    });
    tempoBUs = sheetDataArray.map((row: string[]) => {
      if (row[4] !== 'Central Product') return row[4];
    });
  }

  tempoBUs = Array.from(new Set(tempoBUs));

  const {
    loading: loadingSpecTypes,
    error: errorSpecTypes,
    data: specTypesData,
  } = useQuery(READ_SPEC_TYPES);
  if (!loadingSpecTypes && !errorSpecTypes && specTypesData) {
    const specTypesArray = specTypesData.ReadDataInSheet.sheetData;
    specTypesArray.forEach((row: string[]) => {
      if (row[2] && row[2] !== '') specTypes.push(row[2]);
      if (row[2] === 'Functional Spec')
        functionalSpecType = row[3].split(',').map((name) => name.trim());
      if (row[2] === 'Cost Optimization')
        coSpecType = row[3].split(',').map((name) => name.trim());
    });
  }

  useEffect(() => {
    dispatch(setProductList({ productList: tempoProducts }));
    dispatch(setBuList({ buList: tempoBUs }));
    dispatch(createOrderActions.setBillToBuList({ billToBuList: tempoBUs }));
    dispatch(
      createOrderActions.setProductList({
        productList: tempoProducts,
      }),
    );
  }, [tempoProductList]);

  useEffect(() => {
    dispatch(setSpecTypeList({ specTypeList: specTypes }));
    dispatch(createOrderActions.setSpecTypeList({ specTypeList: specTypes }));
    dispatch(
      createOrderActions.setFunctionalSpecTypeList({
        value: functionalSpecType,
      }),
    );
    dispatch(createOrderActions.setCoTypeList({ value: coSpecType }));
  }, [specTypes]);

  return (
    <div className='App'>
      <Routes>
        <Route path={'/'} element={<Navigate to={'all-orders'} />} />
        <Route path={'all-orders'} element={<AllOrders />} />
        <Route path={'my-orders'} element={<MyOrders />} />
      </Routes>
    </div>
  );
}

export default App;
