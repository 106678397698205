import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface DROPDOWN {
  items: string[];
  label?: string;
  currentValue: string;
  onSelect: (value: string) => void;
  listBoxWidth?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function ComboBox(props: DROPDOWN) {
  const [query, setQuery] = useState('');

  const filteredItems =
    query === ''
      ? props.items
      : props.items.filter((item) => {
          return item.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as='div' value={props.currentValue} onChange={props.onSelect}>
      <Combobox.Label className='block text-sm font-medium text-gray-700'>
        {props.label}
      </Combobox.Label>
      <div className='relative mt-1'>
        <Combobox.Input
          className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3
            pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500
            focus:border-primary-500 sm:text-sm'
          onChange={(event) => setQuery(event.target.value)}
        />
        <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ChevronUpDownIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options
            className={`absolute z-10 mt-1 
          ${
            props.listBoxWidth ? props.listBoxWidth : 'w-full lg:w-40'
          } bg-white shadow-lg max-h-60 rounded-md py-1
              text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
          >
            {filteredItems.map((item) => (
              <Combobox.Option
                className={({ active }) =>
                  classNames(
                    active ? 'bg-primary-300' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  )
                }
                key={item}
                value={item}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      )}
                    >
                      {item}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-primary' : 'text-primary',
                        )}
                      >
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
