import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { store } from './store/store';
import './index.css';
import App from './App';
import awsmobile from './aws-exports';

let redirectUrl = 'http://localhost:3000/all-orders/';

if (window.location.hostname.includes('devfactory.com'))
  redirectUrl = 'https://spec-orders.devfactory.com/all-orders/';

if (window.location.hostname.includes('prod.d2fxqyhie50snb.amplifyapp.com'))
  redirectUrl = 'https://prod.d2fxqyhie50snb.amplifyapp.com/all-orders/';

if (window.location.hostname.includes('dev.d2fxqyhie50snb.amplifyapp.com'))
  redirectUrl = 'https://dev.d2fxqyhie50snb.amplifyapp.com/all-orders/';

if (window.location.hostname.includes('staging.d2fxqyhie50snb.amplifyapp.com'))
  redirectUrl = 'https://staging.d2fxqyhie50snb.amplifyapp.com/all-orders/';

awsmobile.oauth.redirectSignIn = redirectUrl;
awsmobile.oauth.redirectSignOut = redirectUrl;

Amplify.configure(awsmobile);

const client = new ApolloClient({
  uri: 'https://vzkh3hct5vfejlbnb25h5vrxzu.appsync-api.us-east-1.amazonaws.com/graphql',
  headers: {
    'x-api-key': 'da2-smukgqxmpzhgpcgxz6agworkgi',
  },
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
);
