import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../store/AlertSlice';
import AppNavbar from './AppNavbar';
import { TStore } from '../../store/store';
import LoadingIndicator from '../views/LoadingIndicator';
import CreateOrderModal from '../views/CreateOrderModal';
import Alert from '../Alert';
import { HubCallback } from '@aws-amplify/core/lib/Hub';
import { setIsLoggedIn, setSignInError } from '../../store/auth';
import { Hub } from 'aws-amplify';

interface LAYOUT {
  children: React.ReactNode;
}

function Layout(props: LAYOUT) {
  const dispatch = useDispatch();
  const { showAlert, alertType, message } = useSelector(
    (state: TStore) => state.alertReducer,
  );

  const { isLoggedIn, signInError } = useSelector(
    (state: TStore) => state.authReducer,
  );
  const { isLoading, loadingMessage } = useSelector(
    (state: TStore) => state.appLoadReducer,
  );

  const authListener: HubCallback = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        dispatch(setIsLoggedIn({ isLoggedIn: true }));
        break;
      case 'signOut':
        dispatch(setIsLoggedIn({ isLoggedIn: false }));
        break;
      case 'signIn_failure':
        dispatch(
          setSignInError({
            signInError: decodeURIComponent(
              data.payload.data.message
                .replace('PreSignUp+failed+with+error+', '')
                .replaceAll('+', ' '),
            ),
          }),
        );
        break;
    }
  };

  Hub.listen('auth', authListener);

  return (
    <React.Fragment>
      <AppNavbar />
      {showAlert && (
        <Alert
          type={alertType}
          message={message}
          hideAlert={() =>
            dispatch(alertActions.setShowAlert({ showAlert: false }))
          }
        />
      )}
      <main className={'max-w-6xl mt-8 mb-56 mx-auto px-4 sm:px-6 lg:px-8'}>
        <CreateOrderModal />
        {isLoading && isLoggedIn && (
          <LoadingIndicator loadingMessage={loadingMessage} />
        )}
        {!isLoggedIn && !signInError && <div>Login to continue.</div>}
        {!isLoggedIn && signInError && (
          <div dangerouslySetInnerHTML={{ __html: signInError }} />
        )}
        {isLoggedIn && !isLoading && props.children}
      </main>
    </React.Fragment>
  );
}

export default Layout;
