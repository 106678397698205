import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  COMMON_INITIAL_STATE,
  ESW_DATA_PAYLOAD,
  COMPANY_PAYLOAD,
  COMPANY_LIST_PAYLOAD,
  BU_PAYLOAD,
  BU_LIST_PAYLOAD,
  PRODUCT_PAYLOAD,
  PRODUCT_LIST_PAYLOAD,
  SPEC_TYPE_PAYLOAD,
  SPEC_TYPE_LIST_PAYLOAD,
  STATUS_PAYLOAD,
  STATUS_LIST_PAYLOAD,
  getProductList,
  getBUList,
} from './common';

interface INITIAL_STATE extends COMMON_INITIAL_STATE {
  status: string;
  statusList: string[];
}

const initialState: INITIAL_STATE = {
  eswData: [],
  company: 'Any',
  companyList: ['Any'],
  bu: 'Any',
  buList: ['Any'],
  product: 'Any',
  productList: ['Any'],
  specType: 'Any',
  specTypeList: ['Any'],
  status: 'Any',
  statusList: [],
};

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setESWData: (state, { payload }: PayloadAction<ESW_DATA_PAYLOAD>) => {
      const eswData = payload.eswDataList;
      state.eswData = payload.eswDataList;
      const companyArray: string[] = [];
      eswData.forEach((esw) => {
        companyArray.push(esw.company);
      });
      const companyList = new Set(state.companyList.concat(companyArray));
      state.companyList = Array.from(companyList);
      state.company = state.companyList[0];

      state.buList = Array.from(
        new Set(state.buList.concat(getBUList(state.eswData))),
      );
      state.bu = state.buList[0];

      state.productList = Array.from(
        new Set(state.productList.concat(getProductList(state.eswData))),
      );

      state.product = state.productList[0];
    },
    setCompany: (state, { payload }: PayloadAction<COMPANY_PAYLOAD>) => {
      state.company = payload.company;
      localStorage.setItem('COMPANY', payload.company);
    },
    setCompanyList: (
      state,
      { payload }: PayloadAction<COMPANY_LIST_PAYLOAD>,
    ) => {
      state.companyList = payload.companyList;
    },
    setBu: (state, { payload }: PayloadAction<BU_PAYLOAD>) => {
      state.bu = payload.bu;
      localStorage.setItem('BU', payload.bu);
    },
    setBuList: (state, { payload }: PayloadAction<BU_LIST_PAYLOAD>) => {
      state.buList = Array.from(new Set(state.buList.concat(payload.buList)));
    },
    setProduct: (state, { payload }: PayloadAction<PRODUCT_PAYLOAD>) => {
      state.product = payload.product;
      localStorage.setItem('PRODUCT', payload.product);
    },
    setProductList: (
      state,
      { payload }: PayloadAction<PRODUCT_LIST_PAYLOAD>,
    ) => {
      state.productList = Array.from(
        new Set(state.productList.concat(payload.productList)),
      );
    },
    setSpecType: (state, { payload }: PayloadAction<SPEC_TYPE_PAYLOAD>) => {
      state.specType = payload.specType;
      localStorage.setItem('SPEC_TYPE', payload.specType);
    },
    setSpecTypeList: (
      state,
      { payload }: PayloadAction<SPEC_TYPE_LIST_PAYLOAD>,
    ) => {
      state.specTypeList = Array.from(
        new Set(state.specTypeList.concat(payload.specTypeList)),
      );
      state.specType = state.specTypeList[0];
    },
    setStatus: (state, { payload }: PayloadAction<STATUS_PAYLOAD>) => {
      state.status = payload.status;
      localStorage.setItem('STATUS', payload.status);
    },
    setStatusList: (state, { payload }: PayloadAction<STATUS_LIST_PAYLOAD>) => {
      state.statusList = payload.statusList;
      state.status = state.statusList[0];
    },
  },
});

export const filterReducer = filterSlice.reducer;
export const {
  setESWData,
  setCompany,
  setCompanyList,
  setBu,
  setBuList,
  setProduct,
  setProductList,
  setSpecType,
  setSpecTypeList,
  setStatus,
  setStatusList,
} = filterSlice.actions;
