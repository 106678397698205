import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ORDER_DATA_ITEM } from '../constants/model';
import { BASE_URL } from '../constants/constants';

import {
  SET_ORDER_PAYLOAD,
  SET_TOTAL_PAYLOAD,
  PAGE_SIZE_PAYLOAD,
  PAGE_NUMBER_PAYLOAD,
} from './common';

type IS_LOADING_PAYLOAD = {
  isLoading: boolean;
};

type SET_REQUESTER_EMAIL_PAYLOAD = {
  requesterEmail: string;
};

interface INITIAL_STATE {
  requesterEmail: string;
  orders: ORDER_DATA_ITEM[];
  total: number;
  pageSize: string;
  pageSizeList: string[];
  pageNumber: number;
  isLoading: boolean;
}

const initialState: INITIAL_STATE = {
  requesterEmail: '',
  orders: [],
  total: 0,
  pageNumber: 1,
  pageSizeList: ['10', '20', '50', '100'],
  pageSize: '20',
  isLoading: true,
};

const myOrdersSlice = createSlice({
  name: 'myOrdersSlice',
  initialState,
  reducers: {
    setRequesterEmail: (
      state,
      { payload }: PayloadAction<SET_REQUESTER_EMAIL_PAYLOAD>,
    ) => {
      state.requesterEmail = payload.requesterEmail;
    },
    setOrders: (state, { payload }: PayloadAction<SET_ORDER_PAYLOAD>) => {
      state.orders = payload.orders;
    },
    setTotal: (state, { payload }: PayloadAction<SET_TOTAL_PAYLOAD>) => {
      state.total = payload.totalOrders;
    },
    setPageSize: (state, { payload }: PayloadAction<PAGE_SIZE_PAYLOAD>) => {
      state.pageSize = payload.pageSize;
    },
    setPageNumber: (state, { payload }: PayloadAction<PAGE_NUMBER_PAYLOAD>) => {
      state.pageNumber = payload.pageNumber;
    },
    setIsLoading: (state, { payload }: PayloadAction<IS_LOADING_PAYLOAD>) => {
      state.isLoading = payload.isLoading;
    },
  },
});

export const myOrderReducer = myOrdersSlice.reducer;
export const myOrderAction = myOrdersSlice.actions;

export function fetchOrdersByRequester(
  email: string,
  pageSize: string,
  pageNumber: number,
) {
  return async (dispatch: any) => {
    dispatch(myOrderAction.setIsLoading({ isLoading: true }));
    try {
      const url = `${BASE_URL}/order?requesterEmail=${encodeURIComponent(
        email,
      )}&pageSize=${pageSize}&page=${pageNumber}`;
      console.log(url);
      const res = await fetch(url);
      const data = await res.json();
      console.log(data);
      dispatch(myOrderAction.setOrders({ orders: data.items }));
      dispatch(myOrderAction.setTotal({ totalOrders: data.total }));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(myOrderAction.setIsLoading({ isLoading: false }));
    }
  };
}
