import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LOGGED_IN_PAYLOAD {
  isLoggedIn: boolean;
}

type SIGN_IN_ERROR_PAYLOAD = {
  signInError: string;
};

type IS_ADMIN_PAYLOAD = {
  isAdmin: boolean;
};

type SET_USER_EMAIL_PAYLOAD = {
  userEmail: string;
};

const initialState = {
  isLoggedIn: false,
  signInError: '',
  isAdmin: false,
  userEmail: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn: (state, { payload }: PayloadAction<LOGGED_IN_PAYLOAD>) => {
      state.isLoggedIn = payload.isLoggedIn;
    },
    setSignInError: (
      state,
      { payload }: PayloadAction<SIGN_IN_ERROR_PAYLOAD>,
    ) => {
      state.signInError = payload.signInError;
    },
    setIsAdmin: (state, { payload }: PayloadAction<IS_ADMIN_PAYLOAD>) => {
      state.isAdmin = payload.isAdmin;
    },
    setUserEmail: (
      state,
      { payload }: PayloadAction<SET_USER_EMAIL_PAYLOAD>,
    ) => {
      state.userEmail = payload.userEmail;
    },
  },
});

export const authReducer = authSlice.reducer;
export const { setIsLoggedIn, setSignInError, setIsAdmin, setUserEmail } =
  authSlice.actions;
