import { Fragment, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import ButtonOutlined from '../ui/buttons/ButtonOutlined';
import ButtonFilled from '../ui/buttons/ButtonFilled';
import { TStore } from '../../store/store';
import { createOrderActions } from '../../store/createOrderSlice';
import { alertActions } from '../../store/AlertSlice';
import Dropdown from '../ui/Dropdown';
import ComboBox from '../ui/ComboBox';
import InputText from '../ui/InputText';
import isEmail from 'validator/es/lib/isEmail';
import Checkbox from '../ui/Checkbox';
import { BASE_URL } from '../../constants/constants';

export default function CreateOrderModal() {
  const dispatch = useDispatch();
  const [yourEmailError, setYourEmailError] = useState('');
  const [cpmEmailError, setCpmEmailError] = useState('');
  const [gmEmailError, setGmEmailError] = useState('');
  const [specTitleError, setSpecTitleError] = useState('');
  const [specGoalError, setSpecGoalError] = useState('');
  const [disabled, setIsDisabled] = useState(false);

  const cancelButtonRef = useRef(null);

  const { isAdmin } = useSelector((state: TStore) => state.authReducer);

  const { isOPen } = useSelector((state: TStore) => state.createOrderReducer);
  const {
    product,
    productList,
    billToBu,
    billToBuList,
    specType,
    specTypeList,
    rebuildType,
    rebuildTypeList,
    coType,
    coTypeList,
    yourEmail,
    cpmEmail,
    gmEmail,
    specTitle,
    specGoal,
    specDescription,
    testOrder,
    functionalSpecType,
    functionalSpecTypeList,
  } = useSelector((state: TStore) => state.createOrderReducer);

  async function createOrderHandler() {
    if (!yourEmail || !isEmail(yourEmail))
      setYourEmailError('Valid email required');
    if (!cpmEmail || !isEmail(cpmEmail))
      setCpmEmailError('Valid email required');
    if (!gmEmail || !isEmail(gmEmail)) setGmEmailError('Valid email required');
    if (!specTitle) setSpecTitleError('Required');
    if (!specGoal) setSpecGoalError('Required');
    if (
      isEmail(yourEmail) &&
      isEmail(cpmEmail) &&
      isEmail(gmEmail) &&
      specTitle &&
      specGoal
    ) {
      setIsDisabled(true);
      let specTitleWithSubType = specTitle;
      if (rebuildType) specTitleWithSubType += ` - ${rebuildType}`;
      if (coType) specTitleWithSubType += ` - ${coType}`;
      if (functionalSpecType)
        specTitleWithSubType += ` - ${functionalSpecType}`;
      const data = {
        product,
        billToBu,
        requesterEmail: yourEmail,
        cpmEmail,
        gmEmail,
        specType,
        specTitle: specTitleWithSubType,
        specGoal,
        description: specDescription ? specDescription : undefined,
        rebuildType: rebuildType ? rebuildType : undefined,
        costOptimizationType: coType ? coType : undefined,
        functionalSpecType: functionalSpecType ? functionalSpecType : undefined,
        testOrder: testOrder,
      };
      const res = await fetch(`${BASE_URL}/order`, {
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (!res.ok) {
        dispatch(alertActions.setAlertType({ alertType: 'ERROR' }));
        dispatch(
          alertActions.setAlertMessage({
            message: 'Something went wrong. Try again later.',
          }),
        );
        dispatch(alertActions.setShowAlert({ showAlert: true }));
      } else {
        const data = await res.json();
        const id = data.id;
        dispatch(alertActions.setAlertType({ alertType: 'SUCCESS' }));
        dispatch(
          alertActions.setAlertMessage({
            message: `<a href="https://workstation-df.atlassian.net/browse/${id}" target='_blank' rel='noreferrer' class='font-bold underline'>${id}</a> order created successfully.`,
          }),
        );
        dispatch(alertActions.setShowAlert({ showAlert: true }));
      }
      handleModalClose();
      setIsDisabled(false);
    }
  }

  function handleModalClose() {
    setYourEmailError('');
    setCpmEmailError('');
    setGmEmailError('');
    setSpecGoalError('');
    setSpecTitleError('');
    dispatch(createOrderActions.setCpmEmail({ value: '' }));
    dispatch(createOrderActions.setGmEmail({ value: '' }));
    dispatch(createOrderActions.setSpecTitle({ value: '' }));
    dispatch(createOrderActions.setSpecGoal({ value: '' }));
    dispatch(createOrderActions.setSpecDescription({ value: '' }));
    dispatch(createOrderActions.setIsOpen({ isOPen: false }));
  }

  return (
    <Transition.Root show={isOPen} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={handleModalClose}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6'>
              <div className='divide-y divide-gray-200'>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    Create Order
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Check{' '}
                      <a
                        className={'text-blue-500 underline'}
                        target='_blank'
                        rel='noreferrer'
                        href={
                          'https://docs.google.com/document/d/1F7EpB-fyQu4vAKoA7QtJnu0pKnOlJIAFbPNGuE0cMfg/edit'
                        }
                      >
                        Playbook
                      </a>{' '}
                      for details on the process of making orders
                    </p>
                    <p className='text-sm text-gray-500'>
                      For any issue, email to{' '}
                      <a
                        className={'text-blue-500 underline'}
                        href='mailto:central-product-orders@devfactory.com'
                      >
                        central-product-orders@devfactory.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <hr className={'my-4'} />
              <div className={'mt-2 grid grid-cols-1 gap-2'}>
                <div className={'col-span-1 text-left'}>
                  <Dropdown
                    listBoxWidth={'w-full'}
                    items={productList}
                    label={'Product'}
                    currentValue={product}
                    onSelect={(value) =>
                      dispatch(
                        createOrderActions.setProduct({ product: value }),
                      )
                    }
                  />
                </div>
                <div className={'col-span-1 text-left'}>
                  <ComboBox
                    listBoxWidth={'w-full'}
                    items={billToBuList}
                    label={'BillTo BU'}
                    currentValue={billToBu}
                    onSelect={(value) =>
                      dispatch(
                        createOrderActions.setBillToBu({ billToBu: value }),
                      )
                    }
                  />
                </div>
                <div className={'col-span-1 text-left'}>
                  <Dropdown
                    listBoxWidth={'w-full'}
                    items={specTypeList}
                    label={'Spec Type'}
                    currentValue={specType}
                    onSelect={(value) =>
                      dispatch(
                        createOrderActions.setSpecType({ specType: value }),
                      )
                    }
                  />
                </div>
                {specType === 'Rebuild' && (
                  <div className={'col-span-1 text-left'}>
                    <Dropdown
                      listBoxWidth={'w-full'}
                      items={rebuildTypeList}
                      label={'Rebuild Type'}
                      currentValue={rebuildType}
                      onSelect={(value) =>
                        dispatch(
                          createOrderActions.setRebuildType({ value: value }),
                        )
                      }
                    />
                  </div>
                )}
                {specType === 'Cost Optimization' && (
                  <div className={'col-span-1 text-left'}>
                    <Dropdown
                      listBoxWidth={'w-full'}
                      items={coTypeList}
                      label={'Cost Optimization Type'}
                      currentValue={coType}
                      onSelect={(value) =>
                        dispatch(createOrderActions.setCoType({ value: value }))
                      }
                    />
                  </div>
                )}
                {specType === 'Functional Spec' && (
                  <div className={'col-span-1 text-left'}>
                    <Dropdown
                      listBoxWidth={'w-full'}
                      items={functionalSpecTypeList}
                      label={'Functional Spec Type'}
                      currentValue={functionalSpecType}
                      onSelect={(value) =>
                        dispatch(
                          createOrderActions.setFunctionalSpecType({
                            value: value,
                          }),
                        )
                      }
                    />
                  </div>
                )}
                <div className={'col-span-1 text-left'}>
                  <InputText
                    label={'Your Email'}
                    name={'your_email'}
                    placeholder={'Your Email'}
                    value={yourEmail}
                    error={yourEmailError}
                    readOnly={!isAdmin}
                    onChange={(value) => {
                      dispatch(
                        createOrderActions.setYourEmail({ value: value }),
                      );
                      setYourEmailError('');
                    }}
                  />
                </div>
                <div className={'col-span-1 text-left'}>
                  <InputText
                    label={'CPM Email'}
                    name={'cpm_email'}
                    placeholder={'Commercial Product Manager Email'}
                    value={cpmEmail}
                    error={cpmEmailError}
                    onChange={(value) => {
                      dispatch(
                        createOrderActions.setCpmEmail({ value: value }),
                      );
                      setCpmEmailError('');
                    }}
                  />
                </div>
                <div className={'col-span-1 text-left'}>
                  <InputText
                    label={'GM Email'}
                    name={'gm_email'}
                    error={gmEmailError}
                    placeholder={'General Manager Email'}
                    value={gmEmail}
                    onChange={(value) => {
                      dispatch(createOrderActions.setGmEmail({ value: value }));
                      setGmEmailError('');
                    }}
                  />
                </div>
                <div className={'col-span-1 text-left'}>
                  <InputText
                    label={'Spec Title'}
                    name={'spec_title'}
                    error={specTitleError}
                    placeholder={'Spec Title'}
                    value={specTitle}
                    onChange={(value) => {
                      dispatch(
                        createOrderActions.setSpecTitle({ value: value }),
                      );
                      setSpecTitleError('');
                    }}
                  />
                </div>
                <div className={'col-span-1 text-left'}>
                  <InputText
                    label={'Spec Goal'}
                    name={'spec_goal'}
                    error={specGoalError}
                    placeholder={'Spec 1-sentence Goal'}
                    value={specGoal}
                    onChange={(value) => {
                      dispatch(
                        createOrderActions.setSpecGoal({ value: value }),
                      );
                      setSpecGoalError('');
                    }}
                  />
                </div>
                <div className={'col-span-1 text-left'}>
                  <label
                    htmlFor='description'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Description
                  </label>
                  <div className={'mt-1'}>
                    <textarea
                      rows={4}
                      name='description'
                      id='description'
                      className='block w-full focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md'
                      value={specDescription}
                      onChange={(event) =>
                        dispatch(
                          createOrderActions.setSpecDescription({
                            value: event.target.value,
                          }),
                        )
                      }
                    />
                  </div>
                </div>
                {isAdmin && (
                  <div className={'col-span-1 text-left'}>
                    <Checkbox
                      label={'Test Order'}
                      name={'test-order'}
                      checked={testOrder}
                      onChange={(value) =>
                        dispatch(
                          createOrderActions.setTestOrder({ value: value }),
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                <div className={''}>
                  <ButtonFilled
                    disabled={disabled}
                    buttonText={'Create'}
                    onClick={createOrderHandler}
                  />
                </div>
                <div className={'mt-2 sm:mt-0 mr-3 inline-flex justify-center'}>
                  <ButtonOutlined
                    buttonText={'Cancel'}
                    onClick={handleModalClose}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
