import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { myOrderAction } from '../../store/MyOrdersSlice';
import { TStore } from '../../store/store';
import InputText from '../ui/InputText';
import ButtonFilled from '../ui/buttons/ButtonFilled';

interface SEARCH_BY_EMAIL {
  dispatchFetchOrder: (pageNumber: number) => void;
}

function SearchByEmail(props: SEARCH_BY_EMAIL) {
  const dispatch = useDispatch();
  const { requesterEmail } = useSelector(
    (state: TStore) => state.myOrderReducer,
  );
  return (
    <div className='bg-white shadow px-4 py-5 border-b border-gray-200 sm:px-6 rounded'>
      <h3 className='text-lg text-left leading-6 font-medium text-gray-900'>
        Search orders by requester email
      </h3>
      <div
        className={
          'mt-8 flex items-end text-left grid grid-cols-1 gap-4 sm:grid-cols-4'
        }
      >
        <div className='col-span-3'>
          <InputText
            label={'Requester Email'}
            name={'requesterEmail'}
            placeholder={'Requester Email'}
            value={requesterEmail}
            onChange={(value) => {
              dispatch(
                myOrderAction.setRequesterEmail({ requesterEmail: value }),
              );
            }}
          />
        </div>
        <div className='col-span-1'>
          <ButtonFilled
            buttonText={'Search'}
            onClick={() => {
              dispatch(myOrderAction.setPageNumber({ pageNumber: 1 }));
              props.dispatchFetchOrder(1);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchByEmail;
