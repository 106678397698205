import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TStore } from '../store/store';
import { setPageSize, setPageNumber, setOrders } from '../store/orderDataSlice';
import { fetchOrders } from '../store/orderDataSlice';
import Layout from '../components/ui/Layout';
import Filters from '../components/views/Filters';
import { compareObjects } from '../utils';
import OrderTable from '../components/views/OrderTable';

function AllOrders() {
  const dispatch = useDispatch();
  const {
    orders,
    total,
    pageSize,
    pageSizeList,
    pageNumber,
    isOrderDataLoading,
  } = useSelector((state: TStore) => state.orderDataReducer);

  const { company, bu, product, specType, status } = useSelector(
    (state: TStore) => state.filterReducer,
  );

  const { isLoading } = useSelector((state: TStore) => state.appLoadReducer);

  function dispatchFetchOrder(
    currentPageNumber?: number | null,
    currentPageSize?: string,
  ) {
    dispatch(
      fetchOrders(
        company,
        bu,
        product,
        specType,
        status,
        currentPageSize ? currentPageSize : pageSize,
        currentPageNumber ? currentPageNumber : pageNumber,
      ),
    );
  }

  function sortTableRows(order: boolean, orderBy: string) {
    const tableData = JSON.parse(JSON.stringify(orders));
    tableData.sort((a: any, b: any) =>
      compareObjects(a, b, order ? 'asc' : 'dsc', orderBy),
    );
    dispatch(setOrders({ orders: tableData }));
  }

  useEffect(() => {
    if (!isLoading) {
      dispatchFetchOrder();
    }
  }, [isLoading]);

  return (
    <Layout>
      <Filters />
      <OrderTable
        isLoading={isOrderDataLoading}
        orders={orders}
        total={total}
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageSizeList={pageSizeList}
        pageSizeChange={(value) => {
          dispatch(setPageSize({ pageSize: value }));
          dispatch(setPageNumber({ pageNumber: 1 }));
          dispatchFetchOrder(1, value);
        }}
        currentPageChange={(currentPage) => {
          dispatch(setPageNumber({ pageNumber: currentPage }));
          dispatchFetchOrder(currentPage);
        }}
        sortTableRows={(order, orderBy) => sortTableRows(order, orderBy)}
      />
    </Layout>
  );
}

export default AllOrders;
