import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  COMMON_INITIAL_STATE,
  PRODUCT_PAYLOAD,
  PRODUCT_LIST_PAYLOAD,
  SPEC_TYPE_PAYLOAD,
  SPEC_TYPE_LIST_PAYLOAD,
  BILL_TO_BU_PAYLOAD,
  BILL_TO_BU_LIST_PAYLOAD,
} from './common';

type SET_IS_OPEN_PAYLOAD = {
  isOPen: boolean;
};

type SET_SINGLE_VALUE_PAYLOAD = {
  value: string;
};

type SET_STRING_ARRAY_PAYLOAD = {
  value: string[];
};

type SET_BOOLEAN_PAYLOAD = {
  value: boolean;
};

interface INITIAL_STATE extends COMMON_INITIAL_STATE {
  billToBuList: string[];
  billToBu: string;
  isOPen: boolean;
  yourEmail: string;
  cpmEmail: string;
  gmEmail: string;
  rebuildType: string;
  rebuildTypeList: string[];
  coType: string;
  coTypeList: string[];
  functionalSpecType: string;
  functionalSpecTypeList: string[];
  specTitle: string;
  specGoal: string;
  specDescription: string;
  testOrder: boolean;
}

const initialState: INITIAL_STATE = {
  isOPen: false,
  eswData: [],
  company: '',
  companyList: [],
  bu: '',
  buList: [],
  billToBu: '',
  billToBuList: [],
  product: '',
  productList: [],
  specType: '',
  specTypeList: [],
  yourEmail: '',
  cpmEmail: '',
  gmEmail: '',
  rebuildType: '',
  rebuildTypeList: [],
  coType: '',
  coTypeList: [],
  functionalSpecType: '',
  functionalSpecTypeList: [],
  specTitle: '',
  specGoal: '',
  specDescription: '',
  testOrder: false,
};

const createOrderSlice = createSlice({
  name: 'createSlice',
  initialState,
  reducers: {
    setIsOpen: (state, { payload }: PayloadAction<SET_IS_OPEN_PAYLOAD>) => {
      state.isOPen = payload.isOPen;
    },
    setProduct: (state, { payload }: PayloadAction<PRODUCT_PAYLOAD>) => {
      state.product = payload.product;
    },
    setProductList: (
      state,
      { payload }: PayloadAction<PRODUCT_LIST_PAYLOAD>,
    ) => {
      state.productList = Array.from(
        new Set(state.productList.concat(payload.productList)),
      );
      state.product = state.productList[0];
    },
    setBillToBu: (state, { payload }: PayloadAction<BILL_TO_BU_PAYLOAD>) => {
      state.billToBu = payload.billToBu;
    },
    setBillToBuList: (
      state,
      { payload }: PayloadAction<BILL_TO_BU_LIST_PAYLOAD>,
    ) => {
      state.billToBuList = Array.from(
        new Set(state.billToBuList.concat(payload.billToBuList)),
      ).sort();
      state.billToBu = payload.billToBuList[0];
    },
    setSpecType: (state, { payload }: PayloadAction<SPEC_TYPE_PAYLOAD>) => {
      state.specType = payload.specType;
      state.rebuildType = '';
      state.coType = '';
      state.functionalSpecType = '';
      if (payload.specType === 'Rebuild')
        state.rebuildType = state.rebuildTypeList[0];
      if (payload.specType === 'Cost Optimization')
        state.coType = state.coTypeList[0];
      if (payload.specType === 'Functional Spec')
        state.functionalSpecType = state.functionalSpecTypeList[0];
    },
    setSpecTypeList: (
      state,
      { payload }: PayloadAction<SPEC_TYPE_LIST_PAYLOAD>,
    ) => {
      state.specTypeList = payload.specTypeList;
      state.specType = state.specTypeList[0];
      if (state.specType === 'Rebuild')
        state.rebuildType = state.rebuildTypeList[0];
      if (state.specType === 'Cost Optimization')
        state.coType = state.coTypeList[0];
      if (state.specType === 'Functional Spec')
        state.functionalSpecType = state.functionalSpecTypeList[0];
    },
    setYourEmail: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.yourEmail = payload.value;
    },
    setCpmEmail: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.cpmEmail = payload.value;
    },
    setGmEmail: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.gmEmail = payload.value;
    },
    setRebuildType: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.rebuildType = payload.value;
    },
    setRebuildTypeList: (
      state,
      { payload }: PayloadAction<SET_STRING_ARRAY_PAYLOAD>,
    ) => {
      state.rebuildTypeList = payload.value;
    },
    setCoType: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.coType = payload.value;
    },
    setCoTypeList: (
      state,
      { payload }: PayloadAction<SET_STRING_ARRAY_PAYLOAD>,
    ) => {
      state.coTypeList = payload.value;
    },
    setFunctionalSpecType: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.functionalSpecType = payload.value;
    },
    setFunctionalSpecTypeList: (
      state,
      { payload }: PayloadAction<SET_STRING_ARRAY_PAYLOAD>,
    ) => {
      state.functionalSpecTypeList = payload.value;
      state.functionalSpecType = payload.value[0];
    },
    setSpecTitle: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.specTitle = payload.value;
    },
    setSpecGoal: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.specGoal = payload.value;
    },
    setSpecDescription: (
      state,
      { payload }: PayloadAction<SET_SINGLE_VALUE_PAYLOAD>,
    ) => {
      state.specDescription = payload.value;
    },
    setTestOrder: (state, { payload }: PayloadAction<SET_BOOLEAN_PAYLOAD>) => {
      state.testOrder = payload.value;
    },
  },
});

export const createOrderReducer = createOrderSlice.reducer;
export const createOrderActions = createOrderSlice.actions;
