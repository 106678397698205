export type ORDER_DATA_ITEM = {
  bu: string;
  company: string;
  completedAt?: string;
  createdAt: string;
  epicKey: string;
  product: string;
  specTitle: string;
  specType: string;
  specUrl: string;
  status: string;
};

export type TABLE_HEADER = {
  columnLabel: string;
  isSortable: boolean;
  dataKey: string;
  widthPercentage?: string;
  align: 'left' | 'right' | 'center';
};

export const TABLE_HEADERS: TABLE_HEADER[] = [
  {
    columnLabel: 'Spec Title',
    isSortable: true,
    dataKey: 'specTitle',
    // widthPercentage: '40%',
    align: 'left',
  },
  {
    columnLabel: 'Spec Type',
    isSortable: true,
    dataKey: 'specType',
    // widthPercentage: '20%',
    align: 'right',
  },
  {
    columnLabel: 'Status',
    isSortable: true,
    dataKey: 'status',
    // widthPercentage: '20%',
    align: 'right',
  },
  {
    columnLabel: 'Ordered',
    isSortable: true,
    dataKey: 'createdAt',
    // widthPercentage: '20%',
    align: 'right',
  },
  {
    columnLabel: 'Completed',
    isSortable: true,
    dataKey: 'completedAt',
    // widthPercentage: '20%',
    align: 'right',
  },
  {
    columnLabel: 'Spec Link',
    isSortable: false,
    dataKey: 'specUrl',
    // widthPercentage: '10%',
    align: 'right',
  },
];
