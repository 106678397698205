import React from 'react';

interface BUTTON_FILLED {
  buttonText: string;
  onClick: () => void;
  Icon?: React.ReactNode;
  disabled?: boolean;
}

function ButtonFilled(props: BUTTON_FILLED) {
  return (
    <button
      disabled={props.disabled}
      type='button'
      className='disabled:opacity-50 disabled:cursor-progress w-full flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm
      font-medium rounded-md text-white bg-primary hover:bg-primary-900 focus:outline-none
      focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
      onClick={props.onClick}
    >
      {props.Icon}
      {props.buttonText}
    </button>
  );
}

export default ButtonFilled;
