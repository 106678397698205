const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export function formatDate(value) {
  const date = new Date(value);
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export function compareObjects(a, b, order, orderBy) {
  if (!b[orderBy]) return order === 'asc' ? -1 : 1;
  if (!a[orderBy]) return order === 'asc' ? 1 : -1;
  if (a[orderBy] < b[orderBy]) {
    return order === 'asc' ? -1 : 1;
  }
  if (a[orderBy] > b[orderBy]) {
    return order === 'asc' ? 1 : -1;
  }
  return 0;
}
