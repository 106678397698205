import { gql } from '@apollo/client';

export const READ_PRODUCTS_AND_BU = gql`
  query {
    ReadDataInSheet(
      input: {
        sheetId: "13_-6NwWHU8EkkhUVxtahYzIopS1UXyR3T5GWbYZ0VI8"
        range: "Kanban-Product!A2:E150"
      }
    ) {
      message
      sheetData
    }
  }
`;

export const READ_SPEC_TYPES = gql`
  query {
    ReadDataInSheet(
      input: {
        sheetId: "13_-6NwWHU8EkkhUVxtahYzIopS1UXyR3T5GWbYZ0VI8"
        range: "SpecTypes!A2:F50"
      }
    ) {
      message
      sheetData
    }
  }
`;
