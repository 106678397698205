import React from 'react';
import Dropdown from '../ui/Dropdown';
import {
  setBu,
  setCompany,
  setProduct,
  setSpecType,
  setStatus,
} from '../../store/filterSlice';
import { setPageNumber } from '../../store/orderDataSlice';
import ButtonFilled from '../ui/buttons/ButtonFilled';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../store/orderDataSlice';
import { TStore } from '../../store/store';
import ComboBox from '../ui/ComboBox';

function Filters() {
  const dispatch = useDispatch();
  const {
    company,
    companyList,
    bu,
    buList,
    product,
    productList,
    specType,
    specTypeList,
    status,
    statusList,
  } = useSelector((state: TStore) => state.filterReducer);

  const { pageSize } = useSelector((state: TStore) => state.orderDataReducer);

  function searchOrders() {
    dispatch(setPageNumber({ pageNumber: 1 }));
    dispatch(fetchOrders(company, bu, product, specType, status, pageSize, 1));
  }

  return (
    <div className='bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded'>
      <h3 className='text-lg text-left leading-6 font-medium text-gray-900'>
        Search Orders
      </h3>
      <div className='flex items-end text-left grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-6'>
        <div className='col-span-1'>
          <ComboBox
            items={companyList}
            label={'Company'}
            currentValue={company}
            onSelect={(value) => {
              dispatch(setCompany({ company: value }));
            }}
            listBoxWidth={'w-full lg:w-48'}
          />
        </div>
        <div className='col-span-1'>
          <ComboBox
            items={buList}
            label={'Business Unit'}
            currentValue={bu}
            onSelect={(value) => {
              dispatch(setBu({ bu: value }));
            }}
            listBoxWidth={'w-full lg:w-56'}
          />
        </div>
        <div className='col-span-1'>
          <ComboBox
            items={productList}
            label={'Product'}
            currentValue={product}
            onSelect={(value) => {
              dispatch(setProduct({ product: value }));
            }}
            listBoxWidth={'w-full lg:w-56'}
          />
        </div>
        <div className='col-span-1'>
          <ComboBox
            items={specTypeList}
            label={'Spec Type'}
            currentValue={specType}
            onSelect={(value) => dispatch(setSpecType({ specType: value }))}
            listBoxWidth={'w-full lg:w-64'}
          />
        </div>
        <div className='col-span-1'>
          <Dropdown
            items={statusList}
            label={'Status'}
            currentValue={status}
            onSelect={(value) => dispatch(setStatus({ status: value }))}
          />
        </div>
        <div className='col-span-1'>
          <ButtonFilled buttonText={'Search'} onClick={searchOrders} />
        </div>
      </div>
    </div>
  );
}

export default Filters;
