import { Fragment, useState } from 'react';
import { TABLE_HEADER } from '../../constants/model';
import { ORDER_DATA_ITEM } from '../../constants/model';
import { formatDate } from '../../utils';
import Dropdown from './Dropdown';
import ButtonOutlined from './buttons/ButtonOutlined';
import { statusMap } from '../../constants/constants';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from '@heroicons/react/24/outline';

interface TABLE {
  tableHeaders: TABLE_HEADER[];
  orders: ORDER_DATA_ITEM[];
  total: number;
  pageSize: string;
  pageNumber: number;
  pageSizeList: string[];
  pageSizeChange: (value: string) => void;
  currentPageChange: (currentPage: number) => void;
  sortTableRows: (order: boolean, orderBy: string) => void;
}

export default function Table(props: TABLE) {
  const [sortOrder, setSortOrder] = useState(true);
  const [sortColumn, setSortColumn] = useState('');

  function sortTableData(sortColumn: string) {
    setSortColumn(sortColumn);
    setSortOrder((prevState) => !prevState);
    props.sortTableRows(sortOrder, sortColumn);
  }

  return (
    <Fragment>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-t-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    {props.tableHeaders.map((tableHeader) => {
                      return (
                        <th
                          scope={'col'}
                          key={tableHeader.columnLabel}
                          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          onClick={() => sortTableData(tableHeader.dataKey)}
                        >
                          <div className={'inline-flex'}>
                            {tableHeader.columnLabel}
                            {tableHeader.isSortable &&
                              !sortOrder &&
                              tableHeader.dataKey === sortColumn && (
                                <ArrowSmallUpIcon
                                  className='h-5 w-5 text-gray-400'
                                  aria-hidden='true'
                                />
                              )}
                            {tableHeader.isSortable &&
                              sortOrder &&
                              tableHeader.dataKey === sortColumn && (
                                <ArrowSmallDownIcon
                                  className='h-5 w-5 text-gray-400'
                                  aria-hidden='true'
                                />
                              )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className={'bg-white divide-y divide-gray-200'}>
                  {props.orders.map((order) => (
                    <tr key={order.epicKey}>
                      <td className='px-6 py-4 text-sm text-gray-500'>
                        <a
                          href={`https://workstation-df.atlassian.net/browse/${order.epicKey}`}
                          target='_blank'
                          rel='noreferrer'
                          className={'text-blue-500 font-medium'}
                        >
                          {order.specTitle}
                        </a>
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-500'>
                        {order.specType}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {statusMap.get(order.status) === 'Cancelled' && (
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800'>
                            Cancelled
                          </span>
                        )}
                        {statusMap.get(order.status) === 'Done' && (
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800'>
                            Done
                          </span>
                        )}
                        {statusMap.get(order.status) === 'In Progress' && (
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800'>
                            In Progress
                          </span>
                        )}
                        {statusMap.get(order.status) === 'Paused' && (
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800'>
                            Paused
                          </span>
                        )}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {formatDate(order.createdAt)}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {order.completedAt
                          ? formatDate(order.completedAt)
                          : '-'}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/12'>
                        <a
                          href={`${order.specUrl}`}
                          target='_blank'
                          rel='noreferrer'
                          className={'text-blue-500 underline'}
                        >
                          Link
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <nav
            className='shadow bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg'
            aria-label='Pagination'
          >
            <div className='hidden sm:block'>
              <p className='text-sm text-gray-700'>
                Showing{' '}
                <span className='font-medium'>
                  {parseInt(props.pageSize) * (props.pageNumber - 1) + 1}
                </span>{' '}
                to{' '}
                <span className='font-medium'>
                  {parseInt(props.pageSize) * (props.pageNumber - 1) +
                    props.orders.length}
                </span>{' '}
                of <span className='font-medium'>{props.total}</span> results
              </p>
            </div>
            <div className='flex-1 flex justify-between sm:justify-end items-end'>
              <div>
                <Dropdown
                  items={props.pageSizeList}
                  currentValue={props.pageSize}
                  onSelect={(value) => props.pageSizeChange(value)}
                  listBoxWidth={'w-24'}
                />
              </div>
              <div className={'ml-3'}>
                <ButtonOutlined
                  buttonText={'Previous'}
                  disabled={props.pageNumber === 1}
                  onClick={() => {
                    props.currentPageChange(props.pageNumber - 1);
                  }}
                />
              </div>
              <div className={'ml-3'}>
                <ButtonOutlined
                  buttonText={'Next'}
                  disabled={
                    Math.ceil(props.total / parseInt(props.pageSize)) ===
                    props.pageNumber
                  }
                  onClick={() => {
                    props.currentPageChange(props.pageNumber + 1);
                  }}
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </Fragment>
  );
}
