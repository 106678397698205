import React from 'react';

interface CHECKBOX {
  label: string;
  name: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

function Checkbox(props: CHECKBOX) {
  return (
    <div className='relative flex items-start'>
      <div className='flex items-center h-5'>
        <input
          id={props.name}
          aria-describedby={`${props.name}-description`}
          name={props.name}
          type='checkbox'
          checked={props.checked}
          onChange={(event) => props.onChange(event.target.checked)}
          className='focus:ring-primary-500 h-4 w-4 text-primary border-gray-300 rounded'
        />
      </div>
      <div className='ml-3 text-sm'>
        <label htmlFor={props.name} className='font-medium text-gray-700'>
          {props.label}
        </label>
      </div>
    </div>
  );
}

export default Checkbox;
