import { ORDER_DATA_ITEM } from '../constants/model';

export interface ESW_PORTFOLIO_DATA {
  company: string;
  bu: string;
  product: string;
}

export interface COMMON_INITIAL_STATE {
  eswData: ESW_PORTFOLIO_DATA[];
  company: string;
  companyList: string[];
  bu: string;
  buList: string[];
  product: string;
  productList: string[];
  specType: string;
  specTypeList: string[];
}

export type ESW_DATA_PAYLOAD = {
  eswDataList: ESW_PORTFOLIO_DATA[];
};

export type COMPANY_PAYLOAD = {
  company: string;
};

export type COMPANY_LIST_PAYLOAD = {
  companyList: string[];
};

export type BU_PAYLOAD = {
  bu: string;
};

export type BU_LIST_PAYLOAD = {
  buList: string[];
};

export type PRODUCT_PAYLOAD = {
  product: string;
};

export type PRODUCT_LIST_PAYLOAD = {
  productList: string[];
};

export type BILL_TO_BU_PAYLOAD = {
  billToBu: string;
};

export type BILL_TO_BU_LIST_PAYLOAD = {
  billToBuList: string[];
};

export type SPEC_TYPE_PAYLOAD = {
  specType: string;
};

export type SPEC_TYPE_LIST_PAYLOAD = {
  specTypeList: string[];
};

export type STATUS_PAYLOAD = {
  status: string;
};

export type STATUS_LIST_PAYLOAD = {
  statusList: string[];
};

// tempo
export type TEMPO_PRODUCT_LIST_PAYLOAD = {
  productList: string[];
};

export type TEMPO_PRODUCT = {
  product: string;
};

export function getBUList(eswData: ESW_PORTFOLIO_DATA[]) {
  const buList = new Set<string>();
  eswData.forEach((esw) => {
    buList.add(esw.bu);
  });
  return Array.from(buList);
}

export function getProductList(eswData: ESW_PORTFOLIO_DATA[]) {
  const productList = new Set<string>();
  eswData.forEach((esw) => {
    productList.add(esw.product);
  });
  productList.add('Other');
  return Array.from(productList);
}

export type SET_ORDER_PAYLOAD = {
  orders: ORDER_DATA_ITEM[];
};

export type SET_TOTAL_PAYLOAD = {
  totalOrders: number;
};

export type PAGE_SIZE_PAYLOAD = {
  pageSize: string;
};

export type PAGE_NUMBER_PAYLOAD = {
  pageNumber: number;
};
