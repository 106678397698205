export const BASE_URL =
  'https://aezusw0w46.execute-api.us-east-1.amazonaws.com/prod';

export const statusMap = new Map();
statusMap.set('Cancelled', 'Cancelled');
statusMap.set('Done', 'Done');
statusMap.set('Backlog', 'In Progress');
statusMap.set('Ready for Work', 'In Progress');
statusMap.set('Customer Review', 'In Progress');
statusMap.set('Preparing', 'In Progress');
statusMap.set('Assignments In Progress', 'In Progress');
statusMap.set('Work units in progress', 'In Progress');
statusMap.set('In Progress', 'In Progress');
statusMap.set('Request In Review', 'In Progress');
statusMap.set('Dependency Hold', 'Paused');
statusMap.set('Suspended', 'Paused');

export const reverseStatusMap = new Map();
reverseStatusMap.set('Cancelled', ['Cancelled']);
reverseStatusMap.set('Done', ['Done']);
reverseStatusMap.set('In Progress', [
  'Backlog',
  'Ready for Work',
  'Customer Review,Preparing',
  'Assignments In Progress',
  'Work units in progress',
  'In Progress',
  'Request In Review',
]);
reverseStatusMap.set('Paused', ['Dependency Hold', 'Suspended']);
