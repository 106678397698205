import React from 'react';

interface BUTTON_OUTLINED {
  buttonText: string;
  onClick: () => void;
  Icon?: React.ReactNode;
  disabled?: boolean;
}

function ButtonOutlined(props: BUTTON_OUTLINED) {
  return (
    <button
      type='button'
      disabled={props.disabled}
      className='disabled:opacity-50 disabled:cursor-not-allowed w-full flex justify-center px-4 py-2
      border border-gray-300 shadow-sm text-sm font-medium
      rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2
      focus:ring-offset-2 focus:ring-primary-500'
      onClick={props.onClick}
    >
      {props.Icon}
      {props.buttonText}
    </button>
  );
}

export default ButtonOutlined;
