import React from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { useSelector } from 'react-redux';
import GoogleIcon from '../GoogleIcon.svg';
import ButtonOutlined from './ui/buttons/ButtonOutlined';
import { TStore } from '../store/store';

function AuthButton() {
  const { isLoggedIn } = useSelector((state: TStore) => state.authReducer);

  async function handleSignIn() {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSignOut() {
    try {
      await Auth.signOut();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <ButtonOutlined
      buttonText={isLoggedIn ? 'Sign out' : 'Sign in'}
      onClick={isLoggedIn ? handleSignOut : handleSignIn}
      Icon={
        <img
          alt={'Google Icon'}
          src={GoogleIcon}
          className='-ml-1 mr-2 h-5 w-5'
        />
      }
    />
  );
}

export default AuthButton;
