import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../../logo-df.png';
import NewOrderButton from '../NewOrderButton';
import AuthButton from '../AuthButton';
import { TStore } from '../../store/store';

const APP_LINKS = [
  { linkText: 'All Orders', path: '/all-orders' },
  { linkText: 'My Orders', path: '/my-orders' },
];

export default function AppNavbar() {
  const { isLoggedIn, userEmail, isAdmin } = useSelector(
    (state: TStore) => state.authReducer,
  );
  return (
    <Disclosure as='nav' className='shadow bg-secondary'>
      {({ open }) => (
        <>
          <div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex justify-between h-16'>
              <div className='flex'>
                <div className='flex-shrink-0 flex items-center'>
                  <img className='block h-8 w-auto' src={Logo} alt='Workflow' />
                </div>
                <div className='hidden sm:ml-6 sm:flex sm:space-x-8 sm:items-center'>
                  {APP_LINKS.map((link) => {
                    return (
                      <NavLink
                        key={link.path}
                        to={link.path}
                        className={({ isActive }) => {
                          return isActive
                            ? 'border-primary text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex ' +
                                'items-center px-1 pt-1 border-b-2 text-sm font-medium';
                        }}
                      >
                        {link.linkText}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
              <div className='hidden sm:ml-6 sm:flex sm:space-x-8 sm:items-center'>
                {!isLoggedIn && <AuthButton />}
                {isLoggedIn && (
                  <Menu as='div' className='ml-3 relative'>
                    <div>
                      <Menu.Button className='flex py-2 px-3 bg-primary-200 hover:bg-primary-300 text-primary font-bold rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'>
                        <span className='sr-only'>Open user menu</span>
                        {userEmail.charAt(0).toUpperCase()}
                      </Menu.Button>
                    </div>
                    <Transition
                      enter='transition ease-out duration-200'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className='origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <Menu.Item>
                          <p
                            className={
                              'block text-sm truncate px-4 py-2 text-gray-600 bg-gray-100'
                            }
                          >
                            {userEmail}
                            {isAdmin && (
                              <span className={'text-gray-600'}>
                                <br />
                                (Admin)
                              </span>
                            )}
                          </p>
                        </Menu.Item>
                        <hr />
                        <Menu.Item>
                          <button
                            className={
                              'block w-full px-4 py-2 text-sm text-gray-900 hover:bg-primary-200'
                            }
                            onClick={() => Auth.signOut()}
                          >
                            Sign out
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>

              <div className='-mr-2 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button
                  className='inline-flex items-center justify-center p-2 rounded-md text-gray-400
                hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2
                focus:ring-inset focus:ring-primary-500'
                >
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div>
              <p
                className={
                  'block text-sm truncate px-4 py-2 text-gray-600 bg-gray-100'
                }
              >
                {userEmail}
                {isAdmin && (
                  <span className={'text-gray-600'}>
                    <br />
                    (Admin)
                  </span>
                )}
              </p>
            </div>
            <div className='pt-2 pb-3 space-y-1'>
              {APP_LINKS.map((link) => {
                return (
                  <Disclosure.Button key={link.path} as='div'>
                    <NavLink
                      to={link.path}
                      className={({ isActive }) => {
                        return isActive
                          ? 'bg-primary-200 border-primary-500 text-primary-900 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                          : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 ' +
                              'hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium';
                      }}
                    >
                      {link.linkText}
                    </NavLink>
                  </Disclosure.Button>
                );
              })}
            </div>
            <div className='pb-3'>
              <div className={'w-1/2 sm:w-1/3 lg:w-1/4 mx-auto'}>
                <AuthButton />
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
