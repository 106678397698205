/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:214399a1-b451-482a-b509-04597c7951bc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_L7nkb3Nuy",
    "aws_user_pools_web_client_id": "2uahcfq8h4uk1ofd4bb7t10m5v",
    "oauth": {
        "domain": "authspecorder-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/all-orders/,http://localhost:3000/my-orders/,https://dev.d2fxqyhie50snb.amplifyapp.com/all-orders/,https://dev.d2fxqyhie50snb.amplifyapp.com/my-orders/,https://prod.d2fxqyhie50snb.amplifyapp.com/all-orders/,https://prod.d2fxqyhie50snb.amplifyapp.com/my-orders/,https://spec-orders.devfactory.com/all-orders/,https://spec-orders.devfactory.com/my-orders/,https://staging.d2fxqyhie50snb.amplifyapp.com/all-orders/,https://staging.d2fxqyhie50snb.amplifyapp.com/my-orders/",
        "redirectSignOut": "http://localhost:3000/all-orders/,http://localhost:3000/my-orders/,https://dev.d2fxqyhie50snb.amplifyapp.com/all-orders/,https://dev.d2fxqyhie50snb.amplifyapp.com/my-orders/,https://prod.d2fxqyhie50snb.amplifyapp.com/all-orders/,https://prod.d2fxqyhie50snb.amplifyapp.com/my-orders/,https://spec-orders.devfactory.com/all-orders/,https://spec-orders.devfactory.com/my-orders/,https://staging.d2fxqyhie50snb.amplifyapp.com/all-orders/,https://staging.d2fxqyhie50snb.amplifyapp.com/my-orders/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
