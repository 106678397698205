import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ALERT_TYPE_PAYLOAD = {
  alertType: 'ERROR' | 'SUCCESS';
};

type ALERT_MESSAGE_PAYLOAD = {
  message: string;
};

type SHOW_ALERT_PAYLOAD = {
  showAlert: boolean;
};

interface INITIAL_STATE {
  showAlert: boolean;
  alertType: 'ERROR' | 'SUCCESS';
  message: string;
}

const initialState: INITIAL_STATE = {
  showAlert: false,
  alertType: 'ERROR',
  message: '',
};

const alertSlice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    setShowAlert: (state, { payload }: PayloadAction<SHOW_ALERT_PAYLOAD>) => {
      state.showAlert = payload.showAlert;
    },
    setAlertType: (state, { payload }: PayloadAction<ALERT_TYPE_PAYLOAD>) => {
      state.alertType = payload.alertType;
    },
    setAlertMessage: (
      state,
      { payload }: PayloadAction<ALERT_MESSAGE_PAYLOAD>,
    ) => {
      state.message = payload.message;
    },
  },
});

export const alertReducer = alertSlice.reducer;
export const alertActions = alertSlice.actions;
