import React, { Fragment } from 'react';
import LoadingIndicator from './LoadingIndicator';
import Table from '../ui/Table';
import { TABLE_HEADERS } from '../../constants/model';
import { ORDER_DATA_ITEM } from '../../constants/model';

interface ORDER_TABLE {
  isLoading: boolean;
  orders: ORDER_DATA_ITEM[];
  total: number;
  pageNumber: number;
  pageSize: string;
  pageSizeList: string[];
  pageSizeChange: (value: string) => void;
  currentPageChange: (currentPage: number) => void;
  sortTableRows: (order: boolean, orderBy: string) => void;
}

function OrderTable(props: ORDER_TABLE) {
  return (
    <Fragment>
      {props.isLoading && (
        <LoadingIndicator loadingMessage={'Loading orders'} />
      )}
      {!props.isLoading && (
        <div className={'mt-8 text-left'}>
          {props.orders.length > 0 && (
            <Table
              tableHeaders={TABLE_HEADERS}
              orders={props.orders}
              total={props.total}
              pageNumber={props.pageNumber}
              pageSize={props.pageSize}
              pageSizeList={props.pageSizeList}
              pageSizeChange={(value) => props.pageSizeChange(value)}
              currentPageChange={(currentPage) =>
                props.currentPageChange(currentPage)
              }
              sortTableRows={(order, orderBy) =>
                props.sortTableRows(order, orderBy)
              }
            />
          )}
          {props.orders.length === 0 && (
            <div className={'text-center text-gray-900'}>No orders found</div>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default OrderTable;
