import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface DROPDOWN {
  items: string[];
  label?: string;
  currentValue: string;
  onSelect: (value: string) => void;
  listBoxWidth?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Dropdown(props: DROPDOWN) {
  return (
    <Listbox value={props.currentValue} onChange={props.onSelect}>
      {({ open }) => (
        <>
          {props.label && (
            <Listbox.Label className='block text-sm font-medium text-gray-700'>
              {props.label}
            </Listbox.Label>
          )}
          <div className='mt-1 relative'>
            <Listbox.Button
              className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3
            pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500
            focus:border-primary-500 sm:text-sm'
            >
              <span className='block truncate'>{props.currentValue}</span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className={`absolute z-10 mt-1 ${
                  props.listBoxWidth ? props.listBoxWidth : 'w-full lg:w-40'
                } bg-white shadow-lg max-h-60 rounded-md py-1
              text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
              >
                {props.items.map((item) => (
                  <Listbox.Option
                    key={item}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-primary-300' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-8 pr-4',
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {item}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-primary' : 'text-primary',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
