import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { myOrderAction, fetchOrdersByRequester } from '../store/MyOrdersSlice';
import { TStore } from '../store/store';
import Layout from '../components/ui/Layout';
import OrderTable from '../components/views/OrderTable';
import { compareObjects } from '../utils';
import SearchByEmail from '../components/views/SearchByEmail';

function MyOrders() {
  const dispatch = useDispatch();
  const {
    requesterEmail,
    isLoading,
    orders,
    total,
    pageSize,
    pageSizeList,
    pageNumber,
  } = useSelector((state: TStore) => state.myOrderReducer);

  const appLoadReducer = useSelector((state: TStore) => state.appLoadReducer);

  const { isAdmin } = useSelector((state: TStore) => state.authReducer);

  useEffect(() => {
    if (!appLoadReducer.isLoading) dispatchFetchOrder();
  }, [appLoadReducer.isLoading]);

  function dispatchFetchOrder(
    currentPageNumber?: number | null,
    currentPageSize?: string,
  ) {
    dispatch(
      fetchOrdersByRequester(
        requesterEmail,
        currentPageSize ? currentPageSize : pageSize,
        currentPageNumber ? currentPageNumber : pageNumber,
      ),
    );
  }

  function sortTableRows(order: boolean, orderBy: string) {
    const tableData = JSON.parse(JSON.stringify(orders));
    tableData.sort((a: any, b: any) =>
      compareObjects(a, b, order ? 'asc' : 'dsc', orderBy),
    );
    dispatch(myOrderAction.setOrders({ orders: tableData }));
  }

  return (
    <Layout>
      {isAdmin && (
        <SearchByEmail
          dispatchFetchOrder={(pageNumber) => dispatchFetchOrder(pageNumber)}
        />
      )}
      <OrderTable
        isLoading={isLoading || appLoadReducer.isLoading}
        orders={orders}
        total={total}
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageSizeList={pageSizeList}
        pageSizeChange={(value) => {
          dispatch(myOrderAction.setPageSize({ pageSize: value }));
          dispatch(myOrderAction.setPageNumber({ pageNumber: 1 }));
          dispatchFetchOrder(1, value);
        }}
        currentPageChange={(currentPage) => {
          dispatch(myOrderAction.setPageNumber({ pageNumber: currentPage }));
          dispatchFetchOrder(currentPage);
        }}
        sortTableRows={(order, orderBy) => sortTableRows(order, orderBy)}
      />
    </Layout>
  );
}

export default MyOrders;
